import React from 'react'
import styled from 'styled-components'

import { Card as CardComponent } from '@/style-guide'

import squareFoot from '@/assets/images/square-foot.jpg'

const Card = styled(CardComponent)`
  padding: 40px 36px;
  margin-top: 30px;
  min-width: unset;
  max-width: unset;
`

const Title = styled.span`
  font-weight: normal;
  font-size: 21px;
  line-height: 23px;
  color: #092c4c;
`

const Description = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #7f92a3;
`

const Img = styled.img`
  width: 100%;
`

const SectionInfoCard = () => (
  <Card
    styleContent={{ padding: '0px', display: 'flex', flexDirection: 'column' }}
    content={
      <>
        <Title>What is a square foot?</Title>
        <Description>
          <p>
            A square foot or sq ft is an imperial unit of area that is widely used in the United
            States, Canada, China, and the United Kingdom. Its symbol is a simple square with a
            vertical line bisecting it (⏍), commonly used in many branches, such as architecture,
            real estate, and interior space plans. It may also be occasionally written as a square
            with a slash through it. Its abbreviation is commonly written as sqft or sq ft
          </p>
          <Img src={squareFoot} alt="Room" />
          <p>
            As a unit of area, it has a magnitude equivalent to the area of a square with sides of 1
            foot. This size makes it helpful to talk about the area of everyday objects such as a
            house (typically 500-1000 sq ft), a room (~100 sq ft) and even an A4 piece of paper
            (0.65 sq ft) without having to use either very big or very small numbers. There exist,
            obviously, other units of area that can express the same magnitude as the sq ft and
            might even be more suitable for very small objects (like the square inch), very big
            objects (like the acre), or to simply to communicate with the rest of the world by using
            the standardized SI/Metric units (whose default unit of area is the square meter).
          </p>
          <p>
            You will see soon how to convert from square meters to square feet, from square inches
            to square feet, etc... But for now, let&apos;s talk about some situations in which you
            might want to calculate the square footage of something using a simple square footage
            formula. These situations include selling, leasing, renting, or buying a house or a
            room; building a shed or a garage for your car; or maybe even when painting a room. In
            all these situations, our square footage calculator will help you. Although, for the
            last three, we recommend looking at our paint calculator.
          </p>
        </Description>
      </>
    }
  />
)

export default SectionInfoCard
